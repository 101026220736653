/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useRef } from "react";
import { Alert, Drawer } from "antd";
import "../RiskReviewPendingDrawer/riskReviewPendingDrawer.css";
import { BsPlus } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { message } from "antd";
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";
import { FormSwitch } from "../../../core/CustomFormFields";
import { baseUrl } from "../../../../services/services.profile";
import NetworkRiskPDFFile from "../../../modal/NetworkRiskPDFFile";
import ManageConnection from "../ManageConnection";
import { useUserContext } from "../../../../contexts/userContext";
import { getConnection } from "../../../../services/connection";
import { Version } from "../../../../App";
import CustomerAiViewerDrawer from "../AiViewerDrawer/customerAiViewerDrawer";
import RiskAssessementButton from "../AiViewerDrawer/RiskAssessmentButton";
import TranslateDrawer from "../AiViewerDrawer/translateDrawer/translateDrawer";

const PEPbuttonList = [
  { name: "Adverse Media", color: "" },
  { name: "Activity/Funds", color: "" },
  { name: "Region", color: "" },
  { name: "Documents", color: "" },
  { name: "PEP", color: "" },
];

const NetworkRiskReviewPendingDrawer = ({
  isOpen,
  setIsOpen,
  networkDetail,
  getNetworks,
  networks,
  countryCode,
  networkFilter,
  filterList,
  adminDetails,
}) => {
  const { user, RoleManage, userData } = useUserContext();
  let [commentOpen, setCommentOpen] = useState(false);
  const [commentList, setCommentList] = useState([]);
  let [risk, setRisk] = useState("");
  const [prevRisk, setPrevRisk] = useState("");
  const [docList, setDocList] = useState([]);
  const [riskReports, setRiskReports] = useState([]);
  const [description, setDescription] = useState("");
  let [riskRating, setRiskRating] = useState([]);
  let [isRiskRating, setIsRiskRating] = useState(PEPbuttonList);
  let [overallRisk, setOverallRisk] = useState("");
  let [fill, setFill] = useState("");
  let [riskType, setRiskType] = useState({});
  const [recommended, setRecommended] = useState("");
  const [crossGridDrawerOpen, setCrossGridDrawerOpen] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [manageConnectionDrawerOpen, setManageConnectionDrawerOpen] =
    useState(false);
  const [allowRiskAssessment, setAllowRiskAssessment] = useState(false);
  const [memberDetailConnection, setMemberDetailConnection] = useState(null);
  const [isOpenTranslate, setIsOpenTranslate] = useState(false);

  const riskAssessmentDrawerRef = useRef(null);

  const manageConnectionDrawerClose = () => {
    setManageConnectionDrawerOpen(false);
  };

  const crossGridDrawerClose = () => {
    setCrossGridDrawerOpen(false);
  };

  const getRiskAssessmentReportFileName = (item) => {
    const chunks = item.filename.split("_");
    const languageCode = chunks[chunks.length - 1];
    return `Risk Assessment Report_${new Date(item.createdAt)
      .toString()
      .slice(3, 21)}_${languageCode}`;
  };

  const buttonList = [
    {
      id: 1,
      title: "Low",
      border: "border-[#70ad47]",
      text: "text-[#70ad47]",
      bg: "bg-[#70ad47]",
    },
    {
      id: 2,
      title: "Medium",
      border: "border-[#ffc000]",
      text: "text-[#ffc000]",
      bg: "bg-[#ffc000]",
    },
    {
      id: 3,
      title: "High",
      border: "border-[#ff0000]",
      text: "text-[#ff0000]",
      bg: "bg-[#ff0000]",
    },
  ];

  const handleDownloadClick = (networkDetail) => {
    setIsMerging(true);

    // Call the mergeAndDownloadPDFs function when the button is clicked
    mergeAndDownloadPDFs(networkDetail);

    setIsMerging(false);
  };

  const mergeAndDownloadPDFs = async (networkDetail) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (networkDetail?.user_id?.company) {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://api.kyb.pro" +
          "/" +
          networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company.cert &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.cert;
      PolPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.poa_le &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.poa_le;
    } else {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://api.kyb.pro" +
          "/" +
          networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.cert &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push("https://api.kyb.pro" + "/" + el?.filename);
      }
    }
    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // Assuming this is the URL to the PDF
      if (networkDetail?.user_id?.company) {
        let arr = [];
        arr = [CddPdf, IRPdf, UboPdf, PolPdf];
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL (you can also use other methods to fetch the data)
        const response = await fetch(pdfUrl);
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(mergedPdfBlob);
      downloadLink.download = "SupportingDocuments.pdf";

      // Trigger a click event on the download link to initiate the download
      downloadLink.click();
    } catch (error) {
      console.error("Error merging and downloading PDFs:", error);
    }
  };

  const sortedArray = commentList.sort(function (a, b) {
    return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
  });

  const handleComment = async () => {
    setCommentOpen(!commentOpen);
    setDescription("");
  };

  const addComment = async () => {
    const arr = ["Adverse Media", "Activity/Funds", "Region", "Documents"];
    let arr1 = [];
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      for (let i = 0; i < riskRating.length; i++) {
        const el = riskRating[i];
        if (el.name === item) {
          arr1.push(el);
        }
      }
    }
    if (arr1.length !== 4) {
      message.error(
        "Please select the levels of risk for Adverse Media, Activity/Funds, Region and Documents!"
      );
    } else if (risk === "") {
      message.error("Please Select level of risk assessment connection!");
    } else if (description === "") {
      message.error("Please Add Comments!");
    } else {
      const res = await axios
        .post(baseUrl + "/api/network/networkAddRisk", {
          description: description,
          risk: risk,
          network_id: networkDetail?._id,
          risk_rating_list: riskRating,
          risk_type: riskType,
          overrall_Risk: overallRisk,
        })
        .then((res) => {
          setDescription("");
          setRiskRating([]);
          setRisk("");
          setFill("");
          setCommentOpen(true);
          if (prevRisk === risk) {
            message.success("Comment was successfully added");
          } else {
            message.success(res?.data?.message);
          }
          if (filterList.length > 0) {
            networkFilter();
          } else {
            getNetworks();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        });
    }
  };

  const handleConnection = async (item) => {
    setCommentOpen(false);
    let arr1 = [];
    let existRisk = [];
    existRisk = riskRating.find((el) => el.name === item.name);

    if (riskRating.length === 0 || !!!existRisk) {
      riskRating = [...riskRating, item];
      setRiskRating(riskRating);
    }
    if (riskRating.length > 0) {
      for (let i = 0; i < riskRating.length; i++) {
        const el = riskRating[i];
        if (el?.name === item?.name) {
          el.color =
            risk === "Low"
              ? "bg-[#70ad47]"
              : risk === "Medium"
              ? "bg-[#ffc000]"
              : risk === "High"
              ? "bg-[#ff0000]"
              : "bg-[#70ad47]";
          arr1.push(el);
        } else {
          arr1.push(el);
        }
      }
      riskRating = arr1;
      setRiskRating(riskRating);
    }

    let arr = [];
    for (let i = 0; i < isRiskRating.length; i++) {
      const el = isRiskRating[i];
      if (item?.name === el?.name) {
        el.color =
          risk === "Low"
            ? "bg-[#70ad47]"
            : risk === "Medium"
            ? "bg-[#ffc000]"
            : risk === "High"
            ? "bg-[#ff0000]"
            : "bg-[#70ad47]";
        arr.push(el);
      } else {
        arr.push(el);
      }
    }
    setIsRiskRating(arr);
    handleOverallRisk();
  };

  const handleCommentList = async () => {
    const arr = [];
    const arr2 = [];

    for (let i = 0; i < networks?.length; i++) {
      const el = networks[i];
      if (el?._id === networkDetail?._id) {
        setCommentList(el?.comment_list);
        setRisk(el && el?.risk_status);
        setPrevRisk(el && el?.risk_status);
        setDocList(el && el?.document_list);
        const riskReports = (
          el && el?.risk_reports ? [...el?.risk_reports] : []
        ).reverse();
        setRiskReports(riskReports);
        setOverallRisk(el && el?.overrall_Risk);
        if (el?.risk_rating_list.length !== 0) {
          for (let i = 0; i < el?.risk_rating_list.length; i++) {
            const item = el?.risk_rating_list[i];
            arr.push({ name: item?.name, color: item?.color });
          }
          setRiskRating(arr);
        } else {
          setRiskRating(arr);
        }

        if (el?.risk_rating_list.length !== 0) {
          for (let i = 0; i < isRiskRating.length; i++) {
            const item = isRiskRating[i];
            el?.risk_rating_list.forEach((el) => {
              if (el.name === item.name) {
                item.color = el.color;
              }
            });
            arr2.push(item);
          }
          setIsRiskRating(arr2);
        } else {
          for (let i = 0; i < isRiskRating.length; i++) {
            const el = isRiskRating[i];
            for (let i = 0; i < PEPbuttonList.length; i++) {
              const item = PEPbuttonList[i];
              if (item.name === el.name) {
                el.color = "";
              }
            }
            arr2.push(el);
          }
          setIsRiskRating(arr2);
        }
        if (el?.commentList !== 0) {
          setCommentOpen(true);
        }
      }
    }
  };

  const handleUpload = async (e) => {
    const fileObj = e.target.files && e.target.files[0];
    var formData = new FormData();
    formData.append("pdfFile", fileObj);
    formData.append("network_id", networkDetail?._id);
    const res = await axios
      .post(baseUrl + "/api/network/networkToUploadPDF", formData)
      .then((res) => {
        message.success(res?.data?.message);
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        message.error(res?.data?.error);
      });
  };

  const viewPDF = (item) => {
    window.open(baseUrl + "/" + item, "_blank");
  };

  const deletePDF = async (item) => {
    const res = await axios
      .post(baseUrl + "/api/network/networkToDeletePDF", {
        network_id: networkDetail?._id,
        name: item?.filename,
        _id: item?._id,
      })
      .then((res) => {
        message.success(res?.data?.message);
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        message.error(res?.data?.error);
      });
  };

  const handleClose = () => {
    setRisk("");
    setFill("");
    setRiskRating([]);
    setIsRiskRating(PEPbuttonList);
    setIsOpen(false);
    // if (filterList.length > 0) {
    //   networkFilter();
    // } else {
    //   getNetworks();
    // }
  };

  const handleOverallRisk = () => {
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    for (let i = 0; i < isRiskRating.length; i++) {
      const el = isRiskRating[i];
      if (el.color === "bg-[#70ad47]") {
        greenCount++;
      }
      if (el.color === "bg-[#ffc000]") {
        yellowCount++;
      }
      if (el.color === "bg-[#ff0000]") {
        redCount++;
      }
    }
    setOverallRisk(
      greenCount > 0 && yellowCount === 0 && redCount === 0
        ? "Low"
        : yellowCount === 1 && redCount === 0
        ? "Medium"
        : yellowCount > 1 && redCount === 0
        ? "High"
        : redCount > 0
        ? "High"
        : ""
    );
  };

  const handleRemove = (item) => {
    setCommentOpen(false);
    let arr = [];
    let arr1 = [];
    for (let i = 0; i < isRiskRating.length; i++) {
      const el = isRiskRating[i];
      if (el.name === item.name) {
        el.color = "";
        arr.push(el);
      } else {
        arr.push(el);
      }
    }
    setIsRiskRating(arr);

    for (let i = 0; i < riskRating.length; i++) {
      const el = riskRating[i];
      if (el.name === item.name) {
        el.color = "";
        arr1.push(el);
      } else {
        arr1.push(el);
      }
    }
    setRiskRating(arr1);
  };

  useEffect(() => {
    handleCommentList();
  }, [networks]);

  useEffect(() => {
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    for (let i = 0; i < isRiskRating.length; i++) {
      const el = isRiskRating[i];
      if (el.color === "bg-[#70ad47]") {
        greenCount++;
      }
      if (el.color === "bg-[#ffc000]") {
        yellowCount++;
      }
      if (el.color === "bg-[#ff0000]") {
        redCount++;
      }
    }
    setRecommended(
      greenCount > 0 && yellowCount === 0 && redCount === 0
        ? "Low"
        : yellowCount === 1 && redCount === 0
        ? "Medium"
        : yellowCount > 1 && redCount === 0
        ? "High"
        : redCount > 0
        ? "High"
        : ""
    );
  }, [isRiskRating]);

  useEffect(() => {
    if (riskRating.length > 0) {
      let obj = {
        pep: "",
        adversemedia: "",
        activityfunds: "",
        region: "",
        overall_risk: "",
        document: "",
        addedby: userData.fname + " " + userData.lname,
      };
      for (let i = 0; i < riskRating.length; i++) {
        const el = riskRating[i];
        if (el.name === "PEP") {
          obj.pep =
            el?.color === "bg-[#70ad47]"
              ? "Low risk"
              : el?.color === "bg-[#ffc000]"
              ? "Medium risk"
              : el?.color === "bg-[#ff0000]"
              ? "High risk"
              : "";
        }
        if (el.name === "Adverse Media") {
          obj.adversemedia =
            el?.color === "bg-[#70ad47]"
              ? "Low risk"
              : el?.color === "bg-[#ffc000]"
              ? "Medium risk"
              : el?.color === "bg-[#ff0000]"
              ? "High risk"
              : "";
        }
        if (el.name === "Activity/Funds") {
          obj.activityfunds =
            el?.color === "bg-[#70ad47]"
              ? "Low risk"
              : el?.color === "bg-[#ffc000]"
              ? "Medium risk"
              : el?.color === "bg-[#ff0000]"
              ? "High risk"
              : "";
        }
        if (el.name === "Region") {
          obj.region =
            el?.color === "bg-[#70ad47]"
              ? "Low risk"
              : el?.color === "bg-[#ffc000]"
              ? "Medium risk"
              : el?.color === "bg-[#ff0000]"
              ? "High risk"
              : "";
        }
        if (el.name === "Documents") {
          obj.document =
            el?.color === "bg-[#70ad47]"
              ? "Low risk"
              : el?.color === "bg-[#ffc000]"
              ? "Medium risk"
              : el?.color === "bg-[#ff0000]"
              ? "High risk"
              : "";
        }
      }
      obj.overall_risk = !!overallRisk ? overallRisk : "";
      setRiskType(obj);
    }
  }, [riskRating, overallRisk]);

  // Allow Risk Assessment
  useEffect(() => {
    if (user === RoleManage.SECURITY_ROLE) {
      getConnection(networkDetail?._id).then((res) => {
        if (!res?.error) {
          const network = res?.data;
          setMemberDetailConnection(network);
          const getLoginDepartment = userData.department.map(
            (department) => department?._id
          );
          const getDepartment = res?.data.department.find((department) =>
            getLoginDepartment.includes(department?._id)
          );
          setAllowRiskAssessment(getDepartment);
        }
        //  setIsLoading(false);
      });
    }
  }, [isOpen]);

  return (
    <>
      <Drawer
        placement="right"
        className="riskReviewPending"
        closable={false}
        onClose={handleClose}
        visible={isOpen}
      >
        <div className="flex flex-col p-3 h-full gap-4">
          <div className="relative mx-auto bg-white rounded-md shadow-lg p-4 lg:p-5   overflow-auto w-full h-full">
            {networkDetail?.user_id?.removeOn && (
              <Alert
                className="mt-4"
                message="Informational Notes"
                description={
                  <div>
                    <p>
                      We want to inform you that this connection has requested
                      the deletion of their account. All associated information
                      will be permanently removed on{" "}
                      {moment
                        .unix(networkDetail?.user_id?.removeOn / 1000)
                        .format("DD MMM YYYY")}
                      .
                    </p>
                    <br />
                    <p>
                      To comply with AML regulations, please retain any
                      necessary KYC/KYB documents if you have engaged in
                      transactions with this connection. Ensure you download all
                      the Inspection Reports, Risk Assessment Report, and
                      Supporting Documents before{" "}
                      {moment
                        .unix(networkDetail?.user_id?.removeOn / 1000)
                        .format("DD MMM YYYY")}{" "}
                      and store them securely.
                    </p>
                    <br />
                    <p>
                      For any questions, please contact our support team at
                      support@juste.ai.
                    </p>
                  </div>
                }
                type="info"
                showIcon
              />
            )}
            <div
              className="flex justify-end cursor-pointer absolute top-2 left-0 right-0 pr-2"
              onClick={handleClose}
            >
              <IoMdClose
                size={24}
                className="text-gray-500  hover:text-primary flex justify-center items-center"
              />
            </div>
            <div className="relative h-[98%]">
              <div className="flex justify-center flex-col px-2 pb-2 mt-3 tracking-wide relative">
                <div className="flex flex-wrap justify-between items-start gap-3 md:items-center">
                  {/* <p className="text-xl font-bold">Manage Connection</p>
                  <div className="flex gap-3">
                    <p className="text-[#000] font-bold text-base">In-scope</p>
                    <FormSwitch />
                  </div> */}
                  <p className="text-xl font-bold">Risk Assessment</p>
                  <div className="flex gap-3 ">
                    {/* <p className="text-[#000] font-bold text-base">In-scope</p> */}
                    {userData?.current_version === Version.CO_PILOT &&
                      !networkDetail?.user_id?.removeOn && (
                        <button
                          type="button"
                          className="text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide text-white bg-primary px-4 h-8 rounded-lg"
                          onClick={() => setManageConnectionDrawerOpen(true)}
                        >
                          Manage Connection
                        </button>
                      )}
                  </div>
                  <div className="flex justify-center items-center gap-5 pr-5">
                    <button
                      type="button"
                      className="text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide text-white bg-primary px-4 h-8 rounded-lg"
                      onClick={() => setCrossGridDrawerOpen(true)}
                    >
                      Cross-Reference Grid
                    </button>
                    <RiskAssessementButton
                      setIsOpenTranslate={setIsOpenTranslate}
                      ref={riskAssessmentDrawerRef}
                      type={"network"}
                      apiId={networkDetail?._id}
                      fetchConnectionData={getNetworks}
                    />
                  </div>
                </div>
                <div className="pt-4 w-full">
                  <div className="flex gap-3 w-full items-center">
                    <p className="text-[#000810] !m-0 text-[15px]">
                      Connection added:
                    </p>
                    <p className="font-bold text-sm">
                      {moment
                        .unix(networkDetail?.created_time / 1000)
                        .format("DD MMM YYYY")}
                    </p>
                  </div>

                  <div className="w-full flex flex-wrap gap-y-4 justify-between pt-3">
                    {/* Risk assessment on:  */}
                    <div>
                      <p className="text-[#000810] pt-2 text-[15px]">
                        Risk assessment on:
                      </p>
                      <p className="font-bold text-[13px] pt-1">
                        {networkDetail &&
                        networkDetail?.user_id?.company?.status === "passed"
                          ? networkDetail &&
                            networkDetail?.user_id?.company?.name
                          : networkDetail &&
                            networkDetail?.user_id?.fname +
                              " " +
                              networkDetail?.user_id?.lname}
                      </p>

                      <p className="font-bold text-[13px] pt-1">
                        {networkDetail?.user_id?.company?.status === "passed"
                          ? networkDetail &&
                            networkDetail?.user_id?.company?.addr
                              ?.street_address
                          : networkDetail &&
                            networkDetail?.user_id?.amlReport?.appData?.info
                              ?.addresses[0]?.street}
                      </p>

                      <p className="font-bold text-[13px] pt-1">
                        {networkDetail?.user_id?.company?.status === "passed"
                          ? (networkDetail &&
                              networkDetail?.user_id?.company?.addr
                                ?.postal_code) +
                            " " +
                            (networkDetail &&
                              networkDetail?.user_id?.company?.addr?.locality) +
                            " " +
                            (networkDetail &&
                              networkDetail?.user_id?.company?.addr?.country)
                          : (networkDetail &&
                              networkDetail?.user_id?.amlReport?.appData?.info
                                ?.addresses[0]?.postCode) +
                            " " +
                            (networkDetail &&
                              networkDetail?.user_id?.amlReport?.appData?.info
                                ?.addresses[0]?.town) +
                            " " +
                            (networkDetail &&
                              networkDetail?.user_id?.amlReport?.appData?.info
                                ?.addresses[0]?.country)}
                      </p>
                    </div>

                    {/* Contact detail  */}
                    <div className="pr-20">
                      <p className="text-[#000810] pt-2 text-[15px]">
                        Contact:
                      </p>
                      {networkDetail &&
                        networkDetail?.user_id?.company?.status ===
                          "passed" && (
                          <p className="font-bold text-[13px] pt-1">
                            {networkDetail &&
                              networkDetail?.user_id?.fname +
                                " " +
                                networkDetail?.user_id?.lname}
                          </p>
                        )}
                      <p className="font-bold text-[13px] pt-1">
                        {countryCode && "+" + countryCode + " " + " "}
                        {networkDetail?.user_id?.phone}
                      </p>
                      <p className="font-bold text-[13px] pt-1">
                        {networkDetail && networkDetail?.user_id?.email}
                      </p>
                    </div>
                  </div>

                  {/* Select the level of risk based on your assessment: */}
                  <div className="pt-[15px]">
                    <p className="text-[#000810] !m-0 text-[13px]">
                      1. Select the level of risk to tag the type of risk in
                      step 2
                    </p>

                    <div className="flex justify-center ms:justify-start flex-wrap md:flex-nowrap gap-[22px] items-center w-full pt-3">
                      {buttonList?.map((item, index) => (
                        <button
                          type="button"
                          id={`${risk}`}
                          name={`${risk}`}
                          className={`text-xs w-36 ${item?.border} border-2 ${item?.text}  font-Fsemibold px-2 h-8 rounded-lg relative`}
                          onClick={() => {
                            setRisk(`${item?.title}`);
                            setFill(item?.bg);
                            setCommentOpen(false);
                          }}
                          key={index}
                        >
                          {fill === item?.bg && (
                            <div
                              className={`rounded-full ${
                                fill === item?.bg && `${item?.bg}`
                              } w-[9px] h-[9px] absolute top-[9px] left-5`}
                            />
                          )}
                          {item?.title} risk
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Indicate if the connection is related to one: */}
                  <div className="pt-5">
                    <div className="flex justify-between">
                      <p className="text-[#000810] !m-0 text-[13px]">
                        2. Select the risk types that correspond to the
                        activated risk level in step 1
                      </p>
                    </div>
                    <div className="flex justify-center ms:justify-start flex-wrap lg:flex-nowrap gap-y-4 gap-x-[22px] items-end lg:items-start w-full pt-3">
                      {isRiskRating.map(
                        (item, index) =>
                          item?.name !== "PEP" && (
                            <div
                              className="flex flex-col items-center ms:items-start"
                              key={index}
                            >
                              <button
                                key={index}
                                type="button"
                                className="text-xs w-36 border-black border-2 text-black relative gap-3 font-semibold px-2 h-8 rounded-lg flex justify-between items-center"
                              >
                                <div
                                  className="w-full flex justify-center items-center pl-4 h-8"
                                  onClick={() => handleConnection(item)}
                                >
                                  {riskRating && (
                                    <div
                                      className={`rounded-full w-[9px] h-[9px] ${
                                        item?.color && item?.color
                                      } absolute top-[9px] left-5
                                  `}
                                    />
                                  )}
                                  {item?.name === "Region"
                                    ? "Country"
                                    : item?.name}
                                </div>
                              </button>
                            </div>
                          )
                      )}
                    </div>
                  </div>

                  {/* Indicate if the connection is a PEP: */}
                  <div className="pt-5">
                    <div className="flex justify-between">
                      <p className="text-[#000810] !m-0 text-[13px] lg:pr-[10px] lg:block">
                        3. Only if connection is a PEP
                      </p>
                    </div>
                    <div className="flex justify-center ms:justify-start flex-wrap lg:flex-nowrap gap-y-4 gap-x-[22px] items-end lg:items-start w-full pt-3">
                      {isRiskRating.map(
                        (item, index) =>
                          item?.name === "PEP" && (
                            <div
                              className="flex flex-col items-center ms:items-start"
                              key={index}
                            >
                              <button
                                key={index}
                                type="button"
                                className="text-xs w-36 border-black border-2 text-black relative gap-3 font-semibold px-2 h-8 rounded-lg flex justify-between items-center"
                              >
                                <div
                                  className="w-full flex justify-center items-center h-8"
                                  onClick={() => handleConnection(item)}
                                >
                                  {riskRating && (
                                    <div
                                      className={`rounded-full w-[9px] h-[9px] ${
                                        item?.color && item?.color
                                      } absolute top-[9px] left-5
                                  `}
                                    />
                                  )}
                                  {item?.name}
                                </div>

                                {item.color !== "" && (
                                  <IoMdClose
                                    size={17}
                                    className="flex justify-end"
                                    onClick={() => handleRemove(item)}
                                  />
                                )}
                              </button>
                            </div>
                          )
                      )}
                    </div>
                  </div>

                  {/* Overall risk level  */}
                  <div className="flex flex-col justify-start pt-5 w-full">
                    <p className="text-[#000810] !m-0 text-[13px] whitespace-nowrap pb-1">
                      4. Overall risk level:
                    </p>
                    <div className="flex justify-center ms:justify-start flex-wrap items-end gap-[22px] w-full">
                      {buttonList?.map((item, index) => (
                        <div
                          key={index}
                          className="flex flex-col justify-start items-end"
                        >
                          {recommended === item?.title && (
                            <p className="text-[#000810] !m-0 text-[12px] whitespace-nowrap font-bold pb-2 text-center w-full">
                              Recommended
                            </p>
                          )}
                          <div
                            className={`${
                              (overallRisk === "High" ||
                                recommended === "High") &&
                              item?.title !== "High" &&
                              "pb-[26px]"
                            }`}
                          >
                            <button
                              type="button"
                              className={`text-xs w-36 border-black border ${item?.text} font-semibold h-8 rounded-lg relative`}
                              onClick={() => {
                                setOverallRisk(`${item?.title}`);
                                setCommentOpen(false);
                              }}
                            >
                              <div
                                className={`rounded-full ${
                                  overallRisk === item?.title && `${item?.bg}`
                                } w-[9px] h-[9px] absolute top-[9px] left-5`}
                              />
                              {item?.title} risk
                            </button>
                          </div>
                          {((overallRisk === "High" && item.title === "High") ||
                            (recommended === "High" &&
                              item.title === "High")) && (
                            <p className="!m-0 text-[12px] whitespace-nowrap text-[#ff0000] font-normal pt-2 text-center pr-3  w-full">
                              EDD Required
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Add assessment comments here:  */}
                  <div
                    className={`${
                      overallRisk === "High" || recommended === "High"
                        ? "pt-0"
                        : "pt-4"
                    }`}
                  >
                    <p className="text-[#000810] !m-0 py-2 text-[13px]">
                      Add risk assessment comments:
                    </p>

                    {commentOpen === true && commentList?.length !== 0 ? (
                      <div className="p-2.5 w-full h-60 sm:h-52 md:h-44 text-sm overflow-auto text-gray-900 !bg-gray-50 rounded-xl !border-[2px] !border-gray-300">
                        {sortedArray?.map((item, index) => (
                          <div className="mb-5" key={index}>
                            <p className="font-bold text-[14px]">
                              {sortedArray?.length === index + 1
                                ? "Added"
                                : "Updated"}{" "}
                              on:{" "}
                              {moment
                                .unix(item?.createdAt / 1000)
                                .format("DD MMM YYYY")}
                            </p>

                            {item?.risk_types[6]?.addedby_present && (
                              <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                                Added By:{" "}
                                {item?.risk_types[6]?.addedby_present && (
                                  <p className="text-[13px]">
                                    <span className={"font-semibold"}>
                                      {" "}
                                      {
                                        item?.risk_types[6]?.addedby_present
                                      }{" "}
                                    </span>{" "}
                                  </p>
                                )}
                              </p>
                            )}

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              PEP:{" "}
                              {item?.risk_types[0]?.pep_prev !== "" &&
                                item?.risk_types[0]?.pep_present !==
                                  item?.risk_types[0]?.pep_prev && (
                                  <p className="text-[13px]">
                                    <span
                                      className={`${
                                        item?.risk_types[0]?.pep_prev ===
                                        "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[0]?.pep_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[0]?.pep_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : item?.risk_types[0]?.pep_prev === ""
                                          ? "No PEP"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {item?.risk_types[0]?.pep_prev}{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[0]?.pep_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[0]?.pep_present ===
                                        "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[0]?.pep_prev ===
                                            "High risk" &&
                                          item?.risk_types[0]?.pep_present ===
                                            "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[0]?.pep_prev ===
                                            "High risk" &&
                                          item?.risk_types[0]?.pep_present ===
                                            "Medium risk"
                                        ? "decreased"
                                        : item?.risk_types[0]?.pep_present ===
                                          ""
                                        ? "No PEP"
                                        : " increased"}{" "}
                                      {item?.risk_types[0]?.pep_present !==
                                        "" && "to"}
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[0]?.pep_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[0]?.pep_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[0]?.pep_present ===
                                      "High risk"
                                    ? "text-[#ff0000]"
                                    : item?.risk_types[0]?.pep_present === ""
                                    ? "No PEP"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[0]?.pep_present === "" &&
                                item?.risk_types[0]?.pep_prev === ""
                                  ? "No PEP"
                                  : item?.risk_types[0]?.pep_present}{" "}
                              </span>
                            </p>

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Adverse Media:
                              {item?.risk_types[1]?.adversemedia_prev !== "" &&
                                item?.risk_types[1]?.adversemedia_present !==
                                  item?.risk_types[1]?.adversemedia_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[1]
                                          ?.adversemedia_prev === "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[1]
                                              ?.adversemedia_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[1]
                                              ?.adversemedia_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[1]?.adversemedia_prev
                                      }{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[1]
                                        ?.adversemedia_prev === "Medium risk" &&
                                      item?.risk_types[1]
                                        ?.adversemedia_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[1]
                                            ?.adversemedia_prev ===
                                            "High risk" &&
                                          item?.risk_types[1]
                                            ?.adversemedia_present ===
                                            "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[1]
                                            ?.adversemedia_prev ===
                                            "High risk" &&
                                          item?.risk_types[1]
                                            ?.adversemedia_present ===
                                            "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[1]?.adversemedia_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[1]
                                        ?.adversemedia_present === "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[1]
                                        ?.adversemedia_present === "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[1]?.adversemedia_present}{" "}
                              </span>
                            </p>

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Activity/Funds:
                              {item?.risk_types[2]?.activityfunds_prev !== "" &&
                                item?.risk_types[2]?.activityfunds_present !==
                                  item?.risk_types[2]?.activityfunds_prev && (
                                  <p className="text-[13px]">
                                    <span
                                      className={`${
                                        item?.risk_types[2]
                                          ?.activityfunds_prev === "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[2]
                                              ?.activityfunds_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[2]
                                              ?.activityfunds_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[2]?.activityfunds_prev
                                      }{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[2]
                                        ?.activityfunds_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[2]
                                        ?.activityfunds_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[2]
                                            ?.activityfunds_prev ===
                                            "High risk" &&
                                          item?.risk_types[2]
                                            ?.activityfunds_present ===
                                            "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[2]
                                            ?.activityfunds_prev ===
                                            "High risk" &&
                                          item?.risk_types[2]
                                            ?.activityfunds_present ===
                                            "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[2]?.activityfunds_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[2]
                                        ?.activityfunds_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[2]
                                        ?.activityfunds_present === "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {
                                  item?.risk_types[2]?.activityfunds_present
                                }{" "}
                              </span>
                            </p>

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Country:
                              {item?.risk_types[3]?.region_prev !== "" &&
                                item?.risk_types[3]?.region_present !==
                                  item?.risk_types[3]?.region_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[3]?.region_prev ===
                                        "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[3]?.region_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[3]?.region_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {item?.risk_types[3]?.region_prev}{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[3]?.region_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[3]?.region_present ===
                                        "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[3]?.region_prev ===
                                            "High risk" &&
                                          item?.risk_types[3]
                                            ?.region_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[3]?.region_prev ===
                                            "High risk" &&
                                          item?.risk_types[3]
                                            ?.region_present === "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[3]?.region_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[3]?.region_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[3]?.region_present ===
                                      "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[3]?.region_present}{" "}
                              </span>
                            </p>

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Documents:
                              {item?.risk_types[5]?.document_prev !== "" &&
                                item?.risk_types[5]?.document_present !==
                                  item?.risk_types[5]?.document_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[5]?.document_prev ===
                                        "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[5]
                                              ?.document_prev === "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[5]
                                              ?.document_prev === "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {item?.risk_types[5]?.document_prev}{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[5]?.document_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[5]?.document_present ===
                                        "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[5]?.document_prev ===
                                            "High risk" &&
                                          item?.risk_types[5]
                                            ?.document_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[5]?.document_prev ===
                                            "High risk" &&
                                          item?.risk_types[5]
                                            ?.document_present === "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[5]?.document_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[5]?.document_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[5]?.document_present ===
                                      "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[5]?.document_present}{" "}
                              </span>
                            </p>

                            <p className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Overall risk:
                              {item?.risk_types[4]?.overall_risk_prev !== "" &&
                                item?.risk_types[4]?.overall_risk_present !==
                                  item?.risk_types[4]?.overall_risk_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[4]
                                          ?.overall_risk_prev === "Low"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[4]
                                              ?.overall_risk_prev === "Medium"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[4]
                                              ?.overall_risk_prev === "High"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[4]?.overall_risk_prev
                                      }{" "}
                                      risk
                                    </span>
                                    <span>
                                      {item?.risk_types[4]
                                        ?.overall_risk_prev === "Medium" &&
                                      item?.risk_types[4]
                                        ?.overall_risk_present === "Low"
                                        ? " decreased"
                                        : item?.risk_types[4]
                                            ?.overall_risk_prev === "High" &&
                                          item?.risk_types[4]
                                            ?.overall_risk_present === "Low"
                                        ? " decreased"
                                        : item?.risk_types[4]
                                            ?.overall_risk_prev === "High" &&
                                          item?.risk_types[4]
                                            ?.overall_risk_present === "Medium"
                                        ? " decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[4]?.overall_risk_present ===
                                  "Low"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[4]
                                        ?.overall_risk_present === "Medium"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[4]
                                        ?.overall_risk_present === "High"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[4]?.overall_risk_present} risk
                              </span>
                            </p>

                            <p className="text-[14px] font-bold">Comments:</p>
                            <p className="text-[#000810] !m-0 text-[14px] font-normal tracking-wide break-words">
                              {item?.description}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <textarea
                          id="description"
                          name="description"
                          className="p-2.5 w-full h-52 md:h-44 text-sm text-gray-900 !bg-gray-50 rounded-xl border !border-gray-300 outline-none focus:outline-none"
                          placeholder="Write your comments here..."
                          onChange={(e) => setDescription(e?.target?.value)}
                        ></textarea>
                      </div>
                    )}

                    <div
                      className={`flex flex-col ms:flex-row justify-between w-full items-start ms:items-center md:items-end  gap-4 pt-4 "`}
                    >
                      {/* {commentList?.length !== 0 && ( */}
                      <div className="flex flex-col md:flex-row justify-start gap-x-3">
                        <PDFDownloadLink
                          document={
                            <NetworkRiskPDFFile
                              data={sortedArray}
                              userinfo={networkDetail}
                              countryCode={countryCode}
                              networkDetail={networkDetail}
                              docList={docList}
                            />
                          }
                          fileName="RiskAssessmentReport.pdf"
                        >
                          <button
                            type="button"
                            className="text-[13px] underline whitespace-nowrap tracking-wide text-primary w-auto px-2 h-6 ms:h-8 flex items-center text-center hover:cursor-pointer rounded-lg"
                          >
                            Download risk assessment comments report
                          </button>
                        </PDFDownloadLink>
                        <button
                          type="button"
                          className="text-[13px] underline whitespace-nowrap tracking-wide text-primary w-auto px-2 h-6 ms:h-8 flex items-center text-center hover:cursor-pointer rounded-lg"
                          onClick={() => handleDownloadClick(networkDetail)}
                          disabled={isMerging}
                        >
                          Download supporting documents
                        </button>
                      </div>
                      {/* )} */}
                      {!!commentOpen && commentList?.length !== 0 ? (
                        <button
                          type="button"
                          className="text-xs border-primary border-2 font-semibold tracking-wide text-primary w-32 px-2 h-8 rounded-lg whitespace-nowrap"
                          onClick={handleComment}
                        >
                          Add Comment
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="text-xs border-primary border-2 font-semibold tracking-wide text-white w-32 bg-primary px-2 h-8 rounded-lg"
                          onClick={addComment}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Connection Due Diligence Documents  */}
                  <div className="my-4 flex lgs:flex-row flex-col-reverse lg:gap-4 gap-3 ">
                    <div className="grid  lgs:grid-cols-3 grid-cols-1 md:grid-cols-2 lg:gap-4 gap-3 w-full">
                      <div className="flex flex-col gap-3">
                        <p className="font-bold">Inspection Report</p>

                        <div className="flex flex-col gap-2">
                          {!Array.isArray(networkDetail?.risk_reports)
                            ? ""
                            : networkDetail?.user_id?.InspectRp2?.reverse()?.map(
                                (item, index) => (
                                  <div
                                    className="flex justify-between items-center w-full"
                                    key={index}
                                  >
                                    <p
                                      className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden"
                                      onClick={() => viewPDF(item?.filename)}
                                    >
                                      <a
                                        href={`https://api.kyb.pro/${item.fileUrl}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        IR reports as on{" "}
                                        {new Date(item.createdAt)
                                          .toString()
                                          .slice(3, 21)}
                                      </a>
                                    </p>
                                  </div>
                                )
                              )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-3">
                        <p className="font-bold">Supporting Document</p>

                        <div className="flex justify-between flex-col items-center gap-2">
                          {docList?.length !== 0 &&
                            docList?.map((item, index) => (
                              <div
                                className="flex justify-between items-center w-full"
                                key={index}
                              >
                                <p
                                  className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden"
                                  onClick={() => viewPDF(item?.filename)}
                                >
                                  {item?.filename}
                                </p>
                                <HiOutlineTrash
                                  className="text-[20px] text-primary cursor-pointer w-5 h-5"
                                  onClick={() => deletePDF(item)}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                      <div>
                        <p className="font-bold">Risk Assessment Report</p>

                        <div className="flex justify-between flex-col items-center gap-2">
                          {riskReports?.length !== 0 &&
                            riskReports?.map((item, index) => (
                              <div
                                className="flex justify-between items-center w-full"
                                key={`risk_${index}`}
                              >
                                <p
                                  className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden"
                                  onClick={() => viewPDF(item?.filename)}
                                >
                                  {getRiskAssessmentReportFileName(item)}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="!max-w-[140px] !w-full pt-2">
                      <button
                        type="button"
                        onChange={handleUpload}
                        className="text-xs ml-auto border-primary border-2 order-1 ms:order-2 font-semibold flex items-center gap-1 tracking-wide text-primary px-2 h-8 rounded-lg"
                      >
                        <input
                          type="file"
                          id="file-input"
                          name="file-input"
                          accept=".pdf"
                          style={{ display: "none" }}
                        />
                        <label
                          id="file-input-label"
                          htmlFor="file-input"
                          className="flex items-center gap-1 !text-xs"
                        >
                          <BsPlus /> Attach document
                        </label>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {userData?._id !== "652943c69df2a7301b23c9ab" &&
                userData?.current_version === Version.CO_PILOT &&
                user !== RoleManage?.MLRO &&
                (user !== RoleManage?.SECURITY_ROLE ||
                  (user === RoleManage?.SECURITY_ROLE &&
                    (!allowRiskAssessment ||
                      !userData?.allowRiskAssessment))) && (
                  <div className="absolute w-full h-full top-0 hover:cursor-not-allowed"></div>
                )}
            </div>
          </div>
        </div>
      </Drawer>
      {manageConnectionDrawerOpen && (
        <ManageConnection
          manageConnectionDrawerOpen={manageConnectionDrawerOpen}
          manageConnectionDrawerClose={manageConnectionDrawerClose}
          manageConnection={networkDetail}
          connectionType="network"
          adminDetails={adminDetails}
          getData={memberDetailConnection}
          fetchConnectionData={getNetworks} //This is needed to get ( Network or Connections ) data
        />
      )}

      {crossGridDrawerOpen && (
        <CustomerAiViewerDrawer
          customerAiViewerDrawerOpen={crossGridDrawerOpen}
          customerAiViewerDrawerClose={crossGridDrawerClose}
          companyName={networkDetail?.user_id?.company?.name}
          tokenId={networkDetail?.secret_token}
          type={"network"}
          apiId={networkDetail?._id}
          fetchConnectionData={getNetworks}
        />
      )}
      {isOpenTranslate && (
        <TranslateDrawer
          isOpenTranslate={isOpenTranslate}
          handleClose={() => setIsOpenTranslate(false)}
          translateName={"Risk Assessment Report"}
          createAiRiskAssessment={
            riskAssessmentDrawerRef.current.createAiRiskAssessment
          }
          tokenId={networkDetail?.secret_token}
        />
      )}
    </>
  );
};

export default NetworkRiskReviewPendingDrawer;
